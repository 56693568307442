import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";
import TitleBox from "../../component/TitleBox";
import Button from "../../component/Button";

export default function Reservation(){
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [types, setTypes] = useState([]);
    const [info, setInfo] = useState("");
    const [duplicate, setDuplicate] = useState(false);
    const [selectedtype, setSelectedType] = useState(null);
    const [typeavility, setTypeavility] = useState(null);
    const [typemsg, setTypeMsg] = useState(null);
    const [machines, setMachines] = useState([]);
    const [options, setOptions] = useState([]);
    const [reqform, setReqform] = useState({
        machine_id: null,
        date: null,
        time: null,
        option: null,
        comment: "",
    });

    const typechanged = (type) => {
        setSelectedType(type)
        if(type){
            customAxios.get('/reserve/typeavility', {"params":{"type":type}}).then((res)=>{
                setTypeavility(true)
                if(res.data.avility === "True"){
                    customAxios.get('/machine/singletype/availables', {"params":{"type":type}})
                        .then((res)=>setMachines(res.data.data))
                    customAxios.get('/machine/option', {"params":{"type":type}})
                        .then((res)=>setOptions(res.data.data))

                    document.getElementById('machineSelect').value = '';
                    document.getElementById('dateSelect').value = '';
                    document.getElementById('timeSelect').value = '';
                    document.getElementById('optionSelect').value = '';
                } else {
                    setTypeavility(false)
                }

                if(res.data.count>=2){
                    setTypeMsg(<Info>동일기종 예약내역이 {res.data.count} 건 있습니다<br/>예약은 기종당 2건으로 제한됩니다</Info>)
                } else if(res.data.count===1) {
                    setTypeMsg(<Infosub>동일기종 예약내역이 {res.data.count} 건 있습니다</Infosub>)
                } else {
                    setTypeMsg(<Infosub>동일기종 예약내역이 없습니다</Infosub>)
                }
                setInfo(null)
            })
        }
        setReqform({
            machine_id: null,
            date: null,
            time: null,
            option: null,
            comment: "",
        })
    }

    const reqchanged = (e) => {
        setReqform({ ...reqform, [e.target.name]: e.target.value });
    }
    useEffect(() => {
        if(selectedtype && reqform.date && reqform.time){
            customAxios.get('/reserve/datetypeoption', {"params":{
                    "machine":selectedtype,
                    "date":reqform.date,
                    "time":reqform.time}
            })
                .then((res)=>{
                    setDuplicate(false)
                    let resqty = 0
                    console.log(res.data.data.machine_id, reqform.machine_id)
                    res.data.data.map((item) => {
                        if(item.status === "approved" || item.status === "pending"){
                            resqty += 1
                            if(item.machine_id.toString() === reqform.machine_id.toString()){
                                setDuplicate(true)
                            }
                        }
                    })
                    if(res.data.data.length > 0){
                        setInfo(`${selectedtype} 예약이 ${resqty}건 있습니다`)
                    } else {
                        setInfo(`예약이 비어있습니다`)
                    }
                })
        }
    }, [reqform, selectedtype])
    const onSubmit = async(e) => {
        e.preventDefault();
        if(!reqform.machine_id || !reqform.date || !reqform.time || !reqform.option){
            alert('빈 항목이 있습니다');
            return;
        }else{
            submitdata()
        }
    }
    const today = () => {
        const offset = new Date().getTimezoneOffset() * 60000;
        return new Date(Date.now() - offset).toISOString().substring(0,10)
    }
    const maxdate = () => {
        const offset = new Date().getTimezoneOffset() * 60000;
        let now = new Date(Date.now() - offset);
        let maxdate = new Date(now.setMonth(now.getMonth() + 1))
        return maxdate.toISOString().substring(0,10)
    }

    const submitdata = () => {
        if(reqform.date <= maxdate()){
            customAxios.post('/reserve/request', {
                machine_id: reqform.machine_id,
                date: reqform.date,
                reserve_type: reqform.time,
                option: reqform.option,
                comment: reqform.comment
            }).then((res)=>{
                if (res.status === 200) {
                    alert('예약이 요청되었습니다');
                    navigate('/meinkampf');
                }
            })
        } else {
            alert("예약 가능한 날짜를 초과하였습니다")
        }

    }

    useEffect(() => {
        customAxios.get('/user/userdata')
            .then((res)=>{
                setUser(res.data.data.nickname);
            })
        customAxios.get('/machine/types/available')
            .then((res)=>setTypes(res.data.data))
    }, []);

    return (
        <IntroContainer>
            <TitleBox title={"기기 예약"}/>
            <DescContainer>
                    <>
                        <Label>사용자</Label>
                        <Input value={user} disabled={true}/>
                        <Label>기기 타입</Label>
                        <SelectInput onChange={(e) => {
                            typechanged(e.target.value)
                        }}>
                            <Option selected={true} disabled={true} hidden={true}>Type</Option>
                            {types && types.length > 0 ? (
                                types.map((item, index) => (
                                    <Option key={index} value={item.type}>{item.type}</Option>
                                ))
                            ) : null}
                        </SelectInput>
                        <Infosub>{typemsg}</Infosub>
                        <Label>기계</Label>
                        <SelectInput disabled={!typeavility} onChange={reqchanged} name={"machine_id"} id={"machineSelect"}>
                            <Option selected value={""} disabled={true} hidden={true}>Machines</Option>
                            {machines && machines.length > 0 ? (
                                machines.map((item, index) => (
                                    <Option key={index} value={item.machine_id}>{item.detail}</Option>
                                ))
                            ) : null}
                        </SelectInput>
                        <Label>날짜</Label>
                        <Input disabled={!reqform.machine_id} min={today()} max={maxdate()} type={"date"} onChange={reqchanged}
                               name={"date"} id={"dateSelect"}/>
                        <Label>시간</Label>
                        <SelectInput disabled={!reqform.date} onChange={reqchanged} name={"time"} id={"timeSelect"}>
                            <Option selected={true} disabled={true} hidden={true} value={""}>Select...</Option>
                            <Option value={"MORNING"}>MORNING 09:00~13:00 (4HR)</Option>
                            <Option value={"DAY"}>DAY 14:00~18:00 (4HR)</Option>
                            <Option value={"NIGHT"}>NIGHT 24:00~다음날 04:00 (4HR)</Option>
                        </SelectInput>
                        {duplicate ? <Info>해당 시간 예약이 이미 존재합니다</Info>:''}
                        <Infosub>{info}</Infosub>
                        <Label>옵션</Label>
                        <SelectInput disabled={!reqform.time} onChange={reqchanged} name={"option"} id={"optionSelect"}>
                            <Option selected disabled={true} hidden={true} value={""}>Select...</Option>
                            {options && options.length > 0 ? (
                                options.map((item, index) => (
                                    <Option key={index} value={item.name}>{item.name} - {item.price}</Option>
                                ))
                            ) : null}
                        </SelectInput>
                        <Label>요청사항</Label>
                        <Input disabled={!reqform.option} type={"text"} onChange={reqchanged} name={"comment"}/>
                    </>
            </DescContainer>
            <MenuContainer>
                <Button type={"primary"}
                        disable={!reqform.option || duplicate} action={onSubmit} value={"요청"}/>
                <Button action={()=>{navigate("/")}} value={"뒤로가기"}/>
            </MenuContainer>
        </IntroContainer>
    )
}

const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const DescContainer = styled.div`
    font-size: 16px;
    min-height: 200px;
    align-content: center;
    color: #8793a6;
    margin: 10px;
`
const MenuContainer = styled.div`
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #8793a6;
    margin-top: 20px;
`
const Input = styled.input`
    min-width: calc(100% - 10px);
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #9ba2b1;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
`
const SelectInput = styled.select`
    min-width: calc(100% - 10px);
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #9ba2b1;
    border-radius: 5px;
    padding: 5px 0;
`
const Option = styled.option`
    text-align: center;
`
const Label = styled.div`
    color: #252f3f;
    padding: 10px;
    font-size: 14px;
    text-align: start;
`
const Info = styled.p`
    color: #C0392B;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
`
const Infosub = styled.p`
    color: #35414e;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
`