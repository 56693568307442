import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";
import Button from "../../component/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faMap, faPhone, faPizzaSlice} from "@fortawesome/free-solid-svg-icons";

export default function Introduction(){
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const getUserInfo = () => {
        customAxios.get('/user/userdata/min').then((res)=>{
            if(res.status===200){
                if(!res.data.data.nickname){
                    navigate('/onboarding');
                }
                setUser(res.data.data);
            }
        })
    }
    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if(token){
            try{
                getUserInfo()
            }catch(error){
                console.log(error);
            }
        }
    }, []);

    return (
        <IntroContainer>
            <TitleImage src={"images/logo_dark.png"} alt="logo"/>
            <DescContainer>
                {user ?
                    <ProfileContainer>
                        <ProfileImage src={user.picture} alt={"profile"}/>
                        <Button
                            action={() => {
                                navigate("/mypage")
                            }} value={user.nickname}/>
                        <Button type={"primary"}
                                action={() => {
                                    navigate("/res")
                                }} value={"예약"}/>
                        <Button type={"primary"}
                                action={() => {
                                    navigate("/meinkampf")
                                }} value={" 나의예약"}/>
                        <Button type={"primary"}
                                action={() => {
                                    navigate("/schedule")
                                }} value={"예약 조회"}/>
                        <Button action={() => {
                            navigate("/logout")
                        }} value={"로그아웃"}/>
                    </ProfileContainer>
                    : <ProfileContainer>
                        <a href={process.env.REACT_APP_RED_PATH}>
                            <GoogleImg src={"/images/signinwithgoogle.png"}/>
                        </a>
                        <Button type={"primary"}
                                action={() => {
                                    navigate("/schedule")
                                }} value={"예약 조회"}/>
                    </ProfileContainer>

                }
            </DescContainer>
            <BtnContainer>
            <LinkIcon colorhover={"#FF0000"}
                          href="https://www.youtube.com/c/%EC%8B%B8%EC%9D%B4%EB%AE%A4%EC%A7%81%EA%B2%8C%EC%9E%84%EC%9B%94%EB%93%9C" target="_blank">
                    <FontAwesomeIcon icon={faYoutube}/>
                </LinkIcon>
                <LinkIcon colorhover={"#1DA1F2"}
                          href="https://x.com/CYGameworld" target="_blank">
                    <FontAwesomeIcon icon={faTwitter}/>
                </LinkIcon>
                <LinkIcon colorhover={"#27AE60"}
                          href="tel:042-300-6219" target="_blank">
                    <FontAwesomeIcon icon={faPhone}/>
                </LinkIcon>
                <LinkIcon colorhover={"#E67E22"}
                          href="https://naver.me/IDbFndDw" target="_blank">
                    <FontAwesomeIcon icon={faMap}/>
                </LinkIcon>
                <LinkIcon colorhover={"#E67E22"}
                          href="https://kws7894.notion.site/kws7894/77ed1d9618ec44bf99c02fdcd12be8d1?v=6099a5ea8e2f4e079c1b716c097dfb87" target="_blank">
                    <FontAwesomeIcon icon={faPizzaSlice}/>
                </LinkIcon>
            </BtnContainer>
            <Copyright>© 2024. 싸이뮤직게임월드 All rights reserved.</Copyright>
            <Copyright>사업자등록번호 840-34-01265</Copyright>
        </IntroContainer>
    )
}
const IntroContainer = styled.div`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const TitleImage = styled.img`
    position: sticky;
    top: 0;
    width: 200px;
    filter: grayscale(0.2);
`
const GoogleImg = styled.img`
    width: 189px;
    margin-top: 30px;
`
const DescContainer = styled.div`
    width: 100%;
    font-size: 16px;
    color: #8793a6;
`
const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const ProfileImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 10px;
    margin: 20px;
`
const BtnContainer = styled.div`
    margin: 20px;
`
const LinkIcon = styled.a`
    margin: 10px;
    padding: 5px;
    color: gray;
    transition: 0.3s;
    &:hover{
        color: ${(props)=>props.colorhover? props.colorhover:'#333542'};
    }
`
const Copyright = styled.div`
    font-size: 12px;
    color: gray;
`