import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";
import Button from "../../component/Button";
import TitleBox from "../../component/TitleBox";
import QrCodeGenerator from "./KioskQR";

export default function Mypage(){
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [qrdisplay, setQrdisplay] = useState(false)
    const [reqform, setReqform] = useState({
        nickname: null,
        phone: '',
        description: null,
    });

    const showqr = () => {
        setQrdisplay(true)
    }
    const reqchanged = (e) => {
        if(e.target.name === "phone"){
            const regex = /^[0-9\b -]{0,13}$/;
            if (regex.test(e.target.value)) {
                setReqform({...reqform, [e.target.name]:e.target.value});
            }
        }else{
            setReqform({...reqform, [e.target.name]:e.target.value});
        }
    }
    const onSubmit = async(e) => {
        e.preventDefault();
        if(!reqform.nickname || reqform.phone.length < 13){
            alert('빈 항목이 있습니다');
            return;
        } else{
            customAxios.post('/user/onboarding', {
                "nickname": reqform.nickname,
                "phone": reqform.phone,
                "detail": reqform.detail,
            }).then((res)=>{
                if (res.status === 200) {
                    window.location = "/mypage"
                }
            })
        }
    }
    useEffect(() => {
        const url = '/user/userdata/mypage';
        const token = sessionStorage.getItem("token");
        if(token){
            customAxios.get(url, {
                "params":{
                    "token":token
                }
            }).then((res)=>{
                if(!res.data.data.nickname){
                    navigate('/onboarding');
                }
                setUser(res.data.data);
                setReqform({
                    nickname: res.data.data.nickname,
                    phone: res.data.data.phone,
                    detail: res.data.data.detail,
                })
            })
        }
    }, []);
    useEffect(() => {
        const newphone = reqform.phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        setReqform({...reqform, phone:newphone});
    }, [reqform.phone]);


    return (
        <IntroContainer>
            <TitleBox title={"마이페이지"}/>
            <DescContainer>
                {user ?
                    <ProfileContainer>
                        <div>
                            <ProfileImage src={user.picture} alt={"profile"}/>
                        </div>
                        <div>
                            <Button action={showqr} value={"키오스크용 QR코드"}/>
                            <QrCodeGenerator show={qrdisplay} onClose={()=>{setQrdisplay(false)}}/>
                        </div>
                        <DescContainer>
                            <Label>이름 (관리자만 조회가능)</Label>
                            <Input placeholder={"3글자 이상 이름"} maxLength={16} value={reqform.nickname} name={"nickname"} onChange={reqchanged}/>
                            <Label>이메일</Label>
                            <Input maxLength={16} value={user.email} disabled={true}/>
                            <Label>연락처</Label>
                            <Input placeholder={"010-0000-0000 숫자만 입력"} maxLength={14} type={"phone"} value={reqform.phone} name={"phone"} onChange={reqchanged}/>
                            <Label>짧은소개</Label>
                            <Input placeholder={"잘부탁드립니다"} maxLength={120} value={reqform.detail} name={"detail"} onChange={reqchanged}/>
                        </DescContainer>
                        <MenuContainer>
                            <Button action={()=>{navigate("/privacy")}} value={"개인정보 취급 방침"}/>
                            <div>
                                <Button disable={
                                    user.nickname === reqform.nickname && user.detail === reqform.detail &&
                                    (user.phone === reqform.phone || reqform.phone.length !== 13)
                                } type={"submit"} action={onSubmit} value={"적용"}/>
                                <Button action={()=>{navigate("/")}} value={"뒤로가기"}/>
                            </div>
                        </MenuContainer>
                    </ProfileContainer>
                    :null}
            </DescContainer>
        </IntroContainer>
    )
}
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const DescContainer = styled.div`
    font-size: 16px;
    color: #8793a6;
    width: 100%;
`
const Label = styled.div`
    color: #252f3f;
    padding: 10px;
    font-size: 14px;
    text-align: start;
`
const Input = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #9ba2b1;
    text-align: center;
    border-radius: 5px;
    padding: 0;
`
const MenuContainer = styled.div`
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #8793a6;
    margin: 10px 0;
`
const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const ProfileImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 10px;
    margin: 10px;
`