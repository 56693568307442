import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";
import TitleBox from "../../component/TitleBox";
import Button from "../../component/Button";
import ShortCard from "../../component/reservecard/ShortCard";

export default function Checkres(){
    const navigate = useNavigate();
    const [datesel, setDatesel] = useState(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        return formattedDate;
    });
    const [reservations, setReservations] = useState([])
    const [dataexist, setDataexist] = useState({
        morning: false,
        day: false,
        night:false
    })

    useEffect(() => {
        customAxios.get('/reserve/date', {
            params:{"date":datesel}
        }).then((res)=>{
            setDataexist({
                morning: false,
                day: false,
                night:false
            })
            let data = {
                morning: false,
                day: false,
                night:false
            }
            if(res.data.status === "success"){
                setReservations(res.data.data)
                res.data.data.map((item, idx)=> {
                    if(item.time === "MORNING"){
                        data.morning = true
                    }
                    if(item.time === "DAY"){
                        data.day = true
                    }
                    if(item.time === "NIGHT"){
                        data.night = true
                    }
                })
                setDataexist(data)
            }
        })
    }, [datesel]);

    return (
        <IntroContainer>
            <TitleBox title={"예약 조회"}/>
            <Content>
                <Label>조회일</Label>
                <Input type={"date"} onChange={(e)=> setDatesel(e.target.value)} value={datesel}></Input>
                <Label>오전 (09:00~13:00) 4시간</Label>
                {dataexist.morning ? reservations.map((item, index) =>
                    item.time === "MORNING" ? (
                        <ShortCard machine={item.machine_name} status={item.status}/>
                    ) : null
                ) : (
                    <p>내역없음</p>
                )}
                <Label>주간 (14:00~18:00) 4시간</Label>
                {dataexist.day ? reservations.map((item, index) =>
                    item.time === "DAY" ? (
                        <ShortCard machine={item.machine_name} status={item.status}/>
                    ) : null
                ) : (
                    <p>내역없음</p>
                )}
                <Label>밤샘 (24:00~04:00) 4시간</Label>
                {dataexist.night ? reservations.map((item, index) =>
                    item.time === "NIGHT" ? (
                        <ShortCard machine={item.machine_name} status={item.status}/>
                    ) : null
                ) : (
                    <p>내역없음</p>
                )}
                <Description>과거 예약 내역은 보여지지 않습니다</Description>
            </Content>
            {sessionStorage.getItem('token')?<Button type={"primary"}
                    action={()=>{navigate("/meinkampf")}} value={"나의 예약"}/>
            :''}
            <Button action={()=>{navigate("/")}} value={"뒤로가기"}/>
        </IntroContainer>
    )
}
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 400px;
    font-size: 16px;
    color: #8793a6;
    padding: 10px;
`
const Label = styled.div`
    width: 100%;
    color: #252f3f;
    padding: 10px;
    font-size: 14px;
    text-align: start;
`
const Input = styled.input`
    min-width: calc(100% - 10px);
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0;
    border: 1px solid #9ba2b1;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
`
const Description = styled.p`
    margin: 15px 0;
    font-size: 14px;
`