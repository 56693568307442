import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import styled from "styled-components";

function QrCodeGenerator(props) {
    const {show, onClose} = props;

    if (!show) return null;

    // e.stopPropagation()으로 내부 클릭시 배경 클릭 이벤트가 부모에게 전달되지 않도록 방지
    const handleModalContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Modalback onClick={onClose}>
            <Modalcontent  onClick={handleModalContentClick}>
                <QRCodeCanvas
                    value={sessionStorage.getItem('token')}
                    size={370}       // QR 코드의 크기(px)
                    fgColor="#000000" // 전경색
                    bgColor="#ffffff" // 배경색
                    level="L"         // 오류 보정 수준 (L, M, Q, H)
                    includeMargin={true} // 주변 여백을 넣을지 여부
                />
            </Modalcontent>
        </Modalback>
    );
}

export default QrCodeGenerator;

const Modalback = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4); /* 어두운 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* 모달이 다른 요소들 위에 위치하도록 */
`
const Modalcontent = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    /* 모달 내부 크기/위치를 원하는 대로 변경 */
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
`